import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
 
<path d="M1180 2153 c0 -23 30 -114 49 -150 25 -50 78 -105 124 -130 27 -15
63 -19 220 -21 l187 -4 0 25 c0 36 -23 51 -39 25 -12 -19 -20 -20 -164 -16
-158 5 -167 7 -236 58 -29 22 -81 104 -81 128 0 6 28 4 63 -4 37 -7 134 -13
243 -13 211 0 264 -13 325 -78 59 -63 88 -123 59 -123 -5 0 -10 4 -10 9 0 5
-11 12 -24 15 -14 3 -31 12 -38 19 -31 30 -108 49 -108 27 0 -6 3 -9 8 -8 4 1
27 -10 51 -26 24 -15 77 -48 118 -72 l76 -45 -7 43 c-19 116 -100 214 -216
258 -23 9 -111 15 -265 20 -238 6 -269 11 -304 54 -14 17 -31 21 -31 9z"/>
<path d="M1877 1685 c-60 -36 -113 -65 -118 -65 -10 0 -12 -14 -3 -23 8 -8 62
12 117 45 62 36 68 35 54 -7 -16 -47 -67 -98 -124 -127 -42 -20 -65 -23 -188
-26 -79 -1 -150 -8 -163 -14 -12 -6 -33 -31 -47 -55 -33 -54 -61 -84 -107
-110 -42 -24 -123 -40 -132 -25 -7 12 45 176 75 237 12 25 28 45 36 45 7 0 13
6 13 14 0 8 26 27 58 42 52 25 68 28 201 31 118 3 148 1 168 -12 29 -19 43
-12 43 21 l0 24 -174 0 c-183 0 -221 -7 -287 -50 -63 -42 -98 -100 -145 -247
-24 -76 -44 -142 -44 -147 0 -15 139 3 186 23 53 24 116 86 139 139 l18 40
151 4 c128 4 159 8 202 27 62 27 136 100 163 161 23 53 37 120 25 120 -5 -1
-58 -30 -117 -65z"/>
<path d="M760 1018 c-1 -2 -2 -31 -4 -65 -2 -47 1 -63 11 -63 7 0 13 8 13 18
0 9 10 22 23 27 22 10 36 51 20 65 -8 7 -63 23 -63 18z"/>
<path d="M877 1013 c-4 -3 -7 -26 -7 -49 0 -33 5 -46 22 -58 29 -20 34 -20 58
4 25 25 29 110 5 110 -11 0 -15 -12 -15 -50 0 -43 -3 -50 -20 -50 -17 0 -20 7
-20 50 0 46 -7 60 -23 43z"/>
<path d="M1027 1013 c-4 -3 -7 -33 -7 -65 0 -47 3 -58 16 -58 13 0 15 7 11 35
-6 36 6 48 18 18 6 -17 7 -17 19 0 20 26 25 21 19 -18 -4 -28 -2 -35 11 -35
13 0 16 12 16 65 0 73 -18 88 -40 35 l-13 -31 -18 31 c-20 32 -22 34 -32 23z"/>
<path d="M1190 952 l0 -64 32 6 c44 9 58 23 58 61 0 38 -13 51 -57 57 l-33 5
0 -65z m58 26 c16 -16 15 -34 -4 -52 -22 -22 -34 -11 -34 30 0 35 16 44 38 22z"/>
<path d="M1420 955 c0 -69 17 -90 22 -27 l3 37 22 -37 c12 -21 27 -38 33 -38
6 0 10 27 10 66 0 48 -3 64 -12 61 -7 -2 -12 -18 -12 -36 2 -40 -7 -39 -30 4
-11 19 -23 35 -28 35 -4 0 -8 -29 -8 -65z"/>
<path d="M1565 1011 c-3 -2 -5 -31 -5 -63 l0 -58 35 0 c24 0 35 5 35 15 0 8
-9 15 -20 15 -11 0 -20 7 -20 15 0 8 7 15 15 15 8 0 15 5 15 10 0 6 -7 10 -15
10 -8 0 -15 5 -15 10 0 6 9 10 20 10 11 0 20 7 20 15 0 11 -9 15 -30 13 -17
-1 -33 -4 -35 -7z"/>
<path d="M1670 1004 c0 -8 7 -14 15 -14 11 0 15 -12 15 -50 0 -38 4 -50 15
-50 13 0 14 9 10 49 -5 43 -3 49 16 54 33 9 14 24 -31 24 -27 0 -40 -4 -40
-13z"/>
<path d="M1788 1015 c-8 -9 28 -119 40 -123 6 -2 14 13 18 32 3 20 10 36 14
36 4 0 11 -16 14 -36 9 -46 27 -39 42 17 6 24 13 50 15 58 2 7 -1 15 -8 18 -7
2 -15 -13 -21 -39 -9 -43 -14 -40 -26 15 -9 38 -24 34 -32 -8 -9 -50 -20 -51
-28 -5 -6 37 -15 48 -28 35z"/>
<path d="M2152 1012 c-15 -5 -17 -122 -2 -122 6 0 10 11 10 25 0 14 5 25 10
25 6 0 15 -11 20 -25 5 -14 14 -25 20 -25 13 0 13 23 -1 37 -8 8 -7 16 2 30 7
11 10 27 6 35 -6 16 -44 27 -65 20z"/>
<path d="M2270 955 c0 -47 4 -65 12 -63 6 2 12 14 13 26 3 34 11 35 25 2 7
-16 19 -30 26 -30 19 0 18 9 -5 45 -18 29 -18 31 1 58 13 18 15 27 7 27 -7 0
-24 -12 -36 -27 l-24 -28 3 24 c2 13 -3 26 -9 28 -10 4 -13 -13 -13 -62z"/>
<path d="M1987 992 c-23 -25 -21 -58 4 -83 24 -24 65 -20 87 8 15 21 16 65 0
81 -18 18 -73 14 -91 -6z m73 -23 c9 -15 8 -24 -1 -35 -27 -33 -78 0 -58 38
13 24 46 23 59 -3z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
